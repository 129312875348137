import React from 'react';

import Layout from '../layout/index';

import SEO from '../components/seo/index';

import { Accordion, Button } from 'react-bootstrap';

import { faqList } from '../assets/data/faq';

import '../assets/styles/pages/faq.scss';

import aboutbg from '../assets/images/about-bg.svg';

import faq from '../assets/images/faq/faq.png';

import faqone from '../assets/images/faq/faqmazagine.png';

const FAQ = () => {
  return (
    <>
      <SEO title='FAQ' />
      <Layout>
        <div >
          <div className="abtimg">
            <img
              src={aboutbg}
              alt="First slide"
            />
            <p className="text">FAQs</p>
          </div>
        </div>
        <div className='faq-section inner-pages pb-0'>
          <div className='container'>
            <div className='row '>
              <div className='col-md-12'>
                <div>
                  <div className='col-md-12'>
                    <div >
                      <h3 className="align-center header-tag">Hey there, how can we help?</h3>
                      <div className="align-center">
                        <input type="text" className="faq-input" />&nbsp;&nbsp;&nbsp;
                        <Button type="submit" value="Submit" className="faq-btn">Search</Button>
                      </div>
                    </div>
                  </div>
                </div>
                <section className='section'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='row'>
                        <div >
                          <h3 >Frequently asked questions</h3>
                        </div>

                        <div className='col-md-8'>
                          <Accordion>
                            {faqList.map((item) => {
                              return (
                                <Accordion.Item eventKey={item.id} key={item.id}>
                                  <Accordion.Header className="accordion-header">{item.heading}</Accordion.Header>
                                  <Accordion.Body>
                                    <div dangerouslySetInnerHTML={{ __html: item.body }}></div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              );
                            })}
                          </Accordion>
                        </div>
                        <div className='col-md-4'>
                          <div >
                            <img src={faq} alt="faq" />
                          </div>
                          <div style={{ width: '76%', marginLeft: '26px' }}>
                            <img src={faqone} alt="faq" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default FAQ;
